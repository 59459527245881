@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


input {
  height: 40px;
}

button {
  height: 40px;
}

/* ADD USER SECTION*/
.add-user-form {
  width: 400px;
}

.logo-wrapper img {
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}

.users-wrapper {
  margin-top: 80px;
  margin-bottom: 200px;
}

.link-app {
  width:100%;
}